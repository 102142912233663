import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../styles/layout";
import { colors } from "../styles/globalStyles";
import TagsComponent from "../components/tagsComponent";
import AuthorAndSocialMediaComponent from "../components/authorAndSocialMediaComponent";
import CodeHighlighter from "../components/codeHighlighter";
import RelatedArticlesComponent from "../components/relatedArticlesComponent";
import Seo from "../components/seo";
import { Grid, Box, Typography, Container } from "@mui/material";
import ArticleGridItem from "../components/articleGridItem";
import AffiliateView from "../components/affiliateView";
import CombineOperators from "../components/combineOperators";
import { BuyMeACoffeeView } from "../components/buyMeACoffee";
import { graphql } from "gatsby";
import { NewsLetterButton } from "../components/newsLetterButton";
import SocialMediaComponent from "../components/socialMediaComponent";
import { SubHeadline } from "../styles/globalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Article(props) {
  const { data, location } = props;
  const article = data.mdx;
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  let bottomComponent = (
    <div>
      <SubHeadline>Newsletter</SubHeadline>
      <NewsLetterButton />

      <SubHeadline>Like to support my work?</SubHeadline>
      <BuyMeACoffeeView />

      <SubHeadline>Say hi</SubHeadline>
      <SocialMediaComponent />

      <SubHeadline>Related tags</SubHeadline>
      <TagsComponent tags={article.frontmatter.tags}></TagsComponent>

      <RelatedArticlesComponent
        article={article}
        allArticles={data.articles}
      ></RelatedArticlesComponent>

      <AffiliateView type="tanaschita-sponsorship"></AffiliateView>

      <SubHeadline>Latest articles and tips</SubHeadline>
      <Grid container spacing={1}>
        {data.articles.nodes.slice(0, 6).map((node) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={node.id}>
              <ArticleGridItem article={node}></ArticleGridItem>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );

  let dateDescription = "";
  if (article.frontmatter.dateUpdated) {
    dateDescription = "updated on " + article.frontmatter.dateUpdated + " ·  ";
  } else {
    dateDescription = article.frontmatter.date + " ·  ";
  }
  const dateAndReadingTimeDescription =
    dateDescription + article.frontmatter.readingTime + " min read";

  return (
    <Layout location={location}>
      <Seo
        title={article.frontmatter.titleSeo ?? article.frontmatter.title}
        description={article.frontmatter.excerpt}
        lastmod={article.frontmatter.dateUpdated ?? article.frontmatter.date}
        image={"https://tanaschita.com/og/" + article.slug + ".png"}
        article={true}
      />
      <Container>
        <div>
          <Typography variant="h1">{article.frontmatter.title}</Typography>
          <Typography variant="h2">{article.frontmatter.subtitle}</Typography>
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {dateAndReadingTimeDescription}
          </Typography>
          <Box height="12px"></Box>
          <TagsComponent tags={article.frontmatter.tags}></TagsComponent>
          <Box height="12px"></Box>
          <AuthorAndSocialMediaComponent
            isSmall={true}
          ></AuthorAndSocialMediaComponent>

          <Typography component="div">
            {" "}
            <MDXProvider
              components={{
                h4: (props) => (
                  <Typography {...props} component="h4" variant="h4" />
                ),
                h3: (props) => (
                  <Typography {...props} component="h3" variant="h3" />
                ),
                pre: CodeHighlighter,
                a: LinkStyle,
                figcaption: prefersDarkMode ? FigcaptionStyle : FigcaptionStyle,
                figure: FigureStyle,
                inlineCode: prefersDarkMode
                  ? InlineCodeStyleDark
                  : InlineCodeStyle,
                affiliateView: AffiliateView,
                combineOperators: CombineOperators,
              }}
            >
              <MDXRenderer>{article.body}</MDXRenderer>
            </MDXProvider>
          </Typography>
          {bottomComponent}
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    marmot: file(relativePath: { eq: "img/newsletter/marmot.png" }) {
      childImageSharp {
        image: gatsbyImageData(width: 60)
      }
    }
    coffee: file(relativePath: { eq: "img/coffee/coffee.png" }) {
      childImageSharp {
        image: gatsbyImageData(width: 40)
      }
    }
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        titleSeo
        subtitle
        tags
        date(formatString: "DD MMM YYYY")
        dateUpdated(formatString: "DD MMM YYYY")
        readingTime
        excerpt
      }
      slug
      body
      fields {
        contentType
      }
    }

    articles: allMdx(
      filter: { fields: { contentType: { eq: "article" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          titleSeo
          subtitle
          date(formatString: "DD MMM YYYY")
          dateUpdated(formatString: "DD MMM YYYY")
          tags
          readingTime
        }
        slug
        id
      }
    }
  }
`;

const LinkStyle = (props) => (
  <a
    style={{ color: colors.primaryColor, textDecoration: "none" }}
    {...props}
  />
);

const FigureStyle = (props) => (
  <a
    style={{
      display: "block",
      textAlign: "center",
    }}
    {...props}
  />
);

const FigcaptionStyle = (props) => (
  <a
    style={{
      color: colors.textColorLight,
      fontSize: "14px",
    }}
    {...props}
  />
);

const InlineCodeStyle = (props) => (
  <a
    style={{
      fontFamily:
        "Menlo, Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif",
      fontSize: "16px",
      color: "#222222",
      background: "#f2f2f2",
      padding: "2px 4px",
    }}
    {...props}
  />
);

const InlineCodeStyleDark = (props) => (
  <a
    style={{
      fontFamily:
        "Menlo, Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif",
      fontSize: "16px",
      color: "#d6d6d6",
      background: "#383838",
      padding: "2px 4px",
    }}
    {...props}
  />
);
